import { render, staticRenderFns } from "./AppLayout.vue?vue&type=template&id=4bd4cd0a&"
import script from "./AppLayout.vue?vue&type=script&lang=ts&"
export * from "./AppLayout.vue?vue&type=script&lang=ts&"
import style0 from "./AppLayout.vue?vue&type=style&index=0&id=4bd4cd0a&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/app/components/app/header/AppHeader.vue').default,AppFooter: require('/app/components/app/footer/AppFooter.vue').default})
