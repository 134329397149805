// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-display:swap;font-family:\"Montserrat\";font-style:normal;font-weight:400;src:local(\"☺\"),url(/fonts/Montserrat/Montserrat-Regular.woff2) format(\"woff2\"),url(/fonts/Montserrat/Montserrat-Regular.woff) format(\"woff\")}@font-face{font-display:swap;font-family:\"Montserrat\";font-style:normal;font-weight:500;src:local(\"☺\"),url(/fonts/Montserrat/Montserrat-Medium.woff2) format(\"woff2\"),url(/fonts/Montserrat/Montserrat-Medium.woff) format(\"woff\")}@font-face{font-display:swap;font-family:\"Montserrat\";font-style:normal;font-weight:600;src:local(\"☺\"),url(/fonts/Montserrat/Montserrat-Bold.woff2) format(\"woff2\"),url(/fonts/Montserrat/Montserrat-Bold.woff) format(\"woff\")}@font-face{font-display:swap;font-family:\"Moniqa\";font-style:normal;font-weight:400;src:local(\"☺\"),url(/fonts/Moniqa/Moniqa-Display.ttf) format(\"truetype\")}@font-face{font-display:swap;font-family:\"Moniqa\";font-style:normal;font-weight:500;src:local(\"☺\"),url(/fonts/Moniqa/Moniqa-MediumDisplay.ttf) format(\"truetype\")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98"
};
module.exports = ___CSS_LOADER_EXPORT___;
