import { wrap, wrapGql } from "../_"

const pfx = "/badge"

export default (a: NuxtAxiosInstance) => ({
  generateBadge: (colleagueId = "", cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/generate/${colleagueId}`, {},
      { responseType: "blob", ...cfg })),

  downloadUserTicket: () => wrapGql(
    a.$post("/graphql", {
      query: `
        query {
          downloadTicket {
            body
            name
            mimetype
          }
        }
      `,
    }, { responseType: "blob" }),
    "downloadTicket",
    true,
  ),
})
