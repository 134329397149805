import type { NuxtI18nInstance } from "@nuxtjs/i18n"

// TODO: Оборачивать автоматически с сохранением типизации
// Адаптируем вызовы API в стиле golang.
export const wrap = (res: Promise<any>) => res
  .then(r => [r, null]).catch(e => [null, e.response.data])

export const wrapGql = (res: Promise<any>, query: string, blob = false) => res
  .then((r) => {
    if (blob) return [r, null]
    return [r?.data?.[query] || null, null]
  })
  .catch(e => [null, e.response?.data?.errors?.[0] || e.response?.data || null])

/* Для @/plugins/axios */

export const parseRes = (res: any) => res?.data || null

export function parseErr (i18n: NuxtI18nInstance) {
  return (err: any) => {
    const data = err?.response?.data
    return data || err || i18n.t("error.unknownError")
    // return data?.context || data?.message || data?.data?.error ||
    //   data?.data ||
    //   err ||
    //   i18n.t("error.unknownError")
  }
}
