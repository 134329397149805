
import menuMixin from "@/mixins/menu"

export default menuMixin.extend({
  data: () => ({
    navOpen: false,
  }),

  computed: {
    otherLocale (): string {
      const { $i18n } = this
      const { locale } = $i18n
      // @ts-ignore
      return $i18n.locales.filter(l => l.code !== locale)[0]?.code || locale
    },
  },
})
