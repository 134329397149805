import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7120d68d = () => interopDefault(import('../pages/program/index.vue' /* webpackChunkName: "pages/program/index" */))
const _3429c641 = () => interopDefault(import('../pages/speakers/index.vue' /* webpackChunkName: "pages/speakers/index" */))
const _29f8dfcd = () => interopDefault(import('../pages/user/index.ts' /* webpackChunkName: "pages/user/index" */))
const _3fdcfe1b = () => interopDefault(import('../pages/auth/change-password.vue' /* webpackChunkName: "pages/auth/change-password" */))
const _1c75ff9a = () => interopDefault(import('../pages/auth/impersonalize.vue' /* webpackChunkName: "pages/auth/impersonalize" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _23d2ff46 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _c4bfe6f0 = () => interopDefault(import('../pages/user/calendar.vue' /* webpackChunkName: "pages/user/calendar" */))
const _78e0b0a2 = () => interopDefault(import('../pages/user/chat.vue' /* webpackChunkName: "pages/user/chat" */))
const _78875b2f = () => interopDefault(import('../pages/user/payment/index.vue' /* webpackChunkName: "pages/user/payment/index" */))
const _965279dc = () => interopDefault(import('../pages/user/profile/index.vue' /* webpackChunkName: "pages/user/profile/index" */))
const _777e8881 = () => interopDefault(import('../pages/user/recommended.vue' /* webpackChunkName: "pages/user/recommended" */))
const _3c36ac54 = () => interopDefault(import('../pages/user/ticket.vue' /* webpackChunkName: "pages/user/ticket" */))
const _7f033738 = () => interopDefault(import('../pages/schedule-2024/auth/registration.ts' /* webpackChunkName: "pages/schedule-2024/auth/registration" */))
const _0d20bd60 = () => interopDefault(import('../pages/user/payment/participant.vue' /* webpackChunkName: "pages/user/payment/participant" */))
const _f050940c = () => interopDefault(import('../pages/user/profile/edit.vue' /* webpackChunkName: "pages/user/profile/edit" */))
const _73b6d530 = () => interopDefault(import('../pages/schedule-2024/en/auth/registration.ts' /* webpackChunkName: "pages/schedule-2024/en/auth/registration" */))
const _2a6fc7db = () => interopDefault(import('../pages/auth/oauth/_provider.vue' /* webpackChunkName: "pages/auth/oauth/_provider" */))
const _e09f28b4 = () => interopDefault(import('../pages/program/_alias.vue' /* webpackChunkName: "pages/program/_alias" */))
const _2dc57272 = () => interopDefault(import('../pages/speakers/_alias.vue' /* webpackChunkName: "pages/speakers/_alias" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/program",
    component: _7120d68d,
    name: "program___ru"
  }, {
    path: "/speakers",
    component: _3429c641,
    name: "speakers___ru"
  }, {
    path: "/user",
    component: _29f8dfcd,
    name: "user___ru"
  }, {
    path: "/auth/change-password",
    component: _3fdcfe1b,
    name: "auth-change-password___ru"
  }, {
    path: "/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login___ru"
  }, {
    path: "/auth/registration",
    component: _23d2ff46,
    name: "auth-registration___ru"
  }, {
    path: "/en/program",
    component: _7120d68d,
    name: "program___en"
  }, {
    path: "/en/speakers",
    component: _3429c641,
    name: "speakers___en"
  }, {
    path: "/en/user",
    component: _29f8dfcd,
    name: "user___en"
  }, {
    path: "/user/calendar",
    component: _c4bfe6f0,
    name: "user-calendar___ru"
  }, {
    path: "/user/chat",
    component: _78e0b0a2,
    name: "user-chat___ru"
  }, {
    path: "/user/payment",
    component: _78875b2f,
    name: "user-payment___ru"
  }, {
    path: "/user/profile",
    component: _965279dc,
    name: "user-profile___ru"
  }, {
    path: "/user/recommended",
    component: _777e8881,
    name: "user-recommended___ru"
  }, {
    path: "/user/ticket",
    component: _3c36ac54,
    name: "user-ticket___ru"
  }, {
    path: "/en/auth/change-password",
    component: _3fdcfe1b,
    name: "auth-change-password___en"
  }, {
    path: "/en/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___en"
  }, {
    path: "/en/auth/login",
    component: _8e1272a8,
    name: "auth-login___en"
  }, {
    path: "/en/auth/registration",
    component: _23d2ff46,
    name: "auth-registration___en"
  }, {
    path: "/en/user/calendar",
    component: _c4bfe6f0,
    name: "user-calendar___en"
  }, {
    path: "/en/user/chat",
    component: _78e0b0a2,
    name: "user-chat___en"
  }, {
    path: "/en/user/payment",
    component: _78875b2f,
    name: "user-payment___en"
  }, {
    path: "/en/user/profile",
    component: _965279dc,
    name: "user-profile___en"
  }, {
    path: "/en/user/recommended",
    component: _777e8881,
    name: "user-recommended___en"
  }, {
    path: "/en/user/ticket",
    component: _3c36ac54,
    name: "user-ticket___en"
  }, {
    path: "/schedule-2024/auth/registration",
    component: _7f033738,
    name: "schedule-2024-auth-registration___ru"
  }, {
    path: "/user/payment/participant",
    component: _0d20bd60,
    name: "user-payment-participant___ru"
  }, {
    path: "/user/profile/edit",
    component: _f050940c,
    name: "user-profile-edit___ru"
  }, {
    path: "/en/schedule-2024/auth/registration",
    component: _7f033738,
    name: "schedule-2024-auth-registration___en"
  }, {
    path: "/en/user/payment/participant",
    component: _0d20bd60,
    name: "user-payment-participant___en"
  }, {
    path: "/en/user/profile/edit",
    component: _f050940c,
    name: "user-profile-edit___en"
  }, {
    path: "/schedule-2024/en/auth/registration",
    component: _73b6d530,
    name: "schedule-2024-en-auth-registration___ru"
  }, {
    path: "/en/schedule-2024/en/auth/registration",
    component: _73b6d530,
    name: "schedule-2024-en-auth-registration___en"
  }, {
    path: "/en/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___en"
  }, {
    path: "/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru"
  }, {
    path: "/en/program/:alias",
    component: _e09f28b4,
    name: "program-alias___en"
  }, {
    path: "/en/speakers/:alias",
    component: _2dc57272,
    name: "speakers-alias___en"
  }, {
    path: "/program/:alias",
    component: _e09f28b4,
    name: "program-alias___ru"
  }, {
    path: "/speakers/:alias",
    component: _2dc57272,
    name: "speakers-alias___ru"
  }, {
    path: "/en/*",
    component: _2efaf336,
    name: "all___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
